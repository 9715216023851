import Image from "@atoms/Image";

const MediaCard = ({ data, index }) => {
    return (
        <li key={index} className="list-none">
            <a href={data?.url} target="_blank">
                <Image
                    src={`${data?.image}`}
                    className="m-auto xl:hidden"
                    width={data?.mWidth}
                    height={data?.mHeight}
                    alt={data?.alt || ''}
                />
                <Image
                    src={`${data?.image}`}
                    className="hidden m-auto xl:block"
                    width={data?.dWidth}
                    height={data?.dHeight}
                    alt={data?.alt || ''}
                />
            </a>
        </li>
    );
};

export default MediaCard;