import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import { mediaCoverage } from "@helpers/homepageData";
import MediaCard from "./MediaCard";
import Ticker from "react-ticker";
import VisibilitySensor from "react-visibility-sensor";

const MediaCoverage = () => {
    return (
        <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
                <div className="max-width-wrapper py-12 md:py-16 xl:py-18 xl:px-16 flex flex-col items-center">
                    <Text content={texts?.MediaCoverage} className="h4-semibold md:h3-semibold xl:h2-semibold text-primary-500" htmlTag="h2" />
                    <Text content={texts?.MediaSays} className="mt-2 text-basicBlack p3-regular" />
                    {/* Desktop */}
                    <ul className="hidden xl:flex mt-12 flex-row flex-nowrap gap-x-10 items-center">
                        {mediaCoverage?.map((data, index) => (
                            <MediaCard key={index} data={data} index={index} />
                        ))}
                    </ul>
                    {/* Mobile and Tablet */}
                    <div className="w-full relative mt-8 xl:hidden">
                        <Ticker
                            speed={7}
                            move={isVisible}
                        >
                            {({ index }) => <div className="mr-10 h-12 flex justify-center items-center"><MediaCard data={mediaCoverage[index % mediaCoverage.length]} index={index} /></div>

                            }
                        </Ticker>
                    </div>
                </div >)}
        </VisibilitySensor>
    );
};

export default MediaCoverage;